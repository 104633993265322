<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article v-if="companySetting"
        class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Preferences</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="field columns tile is-child pt-0">
          <div v-for="(defaultNumber, index) in defaultNumbers"
            :key="index"
            class="column is-one-third pl-0 py-0"
            :class="{ 'pr-0' : index == defaultNumbers.length - 1 }">
            <p class="label">
              <span class="title is-6">{{ defaultNumber.title }}</span>
            </p>
            <div v-for="(item, itemIndex) in defaultNumber.defaultValue"
              :key="itemIndex"
              class="columns mb-0 is-flex is-align-items-center">
              <div class="column pb-2 is-one-quarter">
                <div class="label">{{ item.label }}</div>
              </div>
              <div class="column pb-2 is-three-quarters">
                <div class="control">
                  <input v-if="item.type == 'Value'"
                    class="input"
                    type="number"
                    min="0"
                    v-model.number="item.value">
                  <input v-else
                    class="input"
                    type="text"
                    v-model="item.value">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field columns tile is-child">
          <div class="column is-half pl-0 py-0">
            <p class="label">
              <span class="title is-6">Misc</span>
            </p>
            <div class="columns mb-0 is-flex is-align-items-center">
              <div class="column is-half pb-2 label">Next Cr. Return No</div>
              <div class="column is-half pb-2">
                <div class="control">
                  <input class="input"
                    type="number"
                    min="0"
                    v-model.number="companySetting.returnValue">
                </div>
              </div>
            </div>
            <div class="columns is-flex is-align-items-center">
              <div class="column is-half label">Next Journal No</div>
              <div class="column is-half">
                <div class="control">
                  <input class="input"
                    type="text"
                    v-model="companySetting.recurringPrefix">
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half pr-0 py-0">
            <p class="label">
              <label class="title is-6">Default Batch Number</label>
            </p>
            <div class="columns is-flex is-align-items-center">
              <div class="column is-half pb-2 label">Next No</div>
              <div class="column is-half pb-2">
                <div class="control">
                  <input class="input"
                    type="number"
                    min="0"
                    v-model.number="batchNo">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field tile is-child">
          <p class="label">
            <label class="title is-6">GST</label>
          </p>
          <div class="columns">
            <div class="column is-half">
              <div class="columns is-flex is-align-items-center">
                <div class="column is-half label">G/L Acct-Collected</div>
                <div class="column is-half">
                  <div class="control is-extended">
                    <div class="select is-fullwidth">
                      <select v-model="companySetting.gstCollectedAccount">
                        <option value="null"
                          disabled>Select</option>
                        <option v-for="(atglAccount, index) in atglAccounts"
                          :key="index"
                          :value="atglAccount.account">
                          {{ atglAccount.account }} {{ atglAccount.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns is-flex is-align-items-center">
                <div class="column is-half label">G/L Acct-Spent</div>
                <div class="column is-half">
                  <div class="control is-extended">
                    <div class="select is-fullwidth">
                      <select v-model="companySetting.gstSpentAccount">
                        <option value="null"
                          disabled>Select</option>
                        <option v-for="(atglAccount, index) in atglAccounts"
                          :key="index"
                          :value="atglAccount.account">
                          {{ atglAccount.account }} {{ atglAccount.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns is-flex is-align-items-center">
                <div class="column is-half label">G/L Acct-With-Hd</div>
                <div class="column is-half">
                  <div class="control is-extended">
                    <div class="select is-fullwidth">
                      <select v-model="companySetting.taxWhAccount">
                        <option value="null"
                          disabled>Select</option>
                        <option v-for="(atglAccount, index) in atglAccounts"
                          :key="index"
                          :value="atglAccount.account">
                          {{ atglAccount.account }} {{ atglAccount.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="columns is-flex is-align-items-center">
                <div class="column is-half label">Accounting Basis</div>
                <div class="column is-half">
                  <div class="control">
                    <input class="input"
                      v-model="companySetting.gstBasis">
                  </div>
                </div>
              </div>

              <div class="columns is-flex is-align-items-center">
                <div class="column is-half label">Accounting Periods</div>
                <div class="column is-half">
                  <div class="control is-extended">
                    <div class="select is-fullwidth">
                      <select v-model="companySetting.gstPeriod">
                        <option value="0"
                          disabled>Select</option>
                        <option v-for="(period, index) in gstPeriod"
                          :key="index"
                          :value="period.period">
                          {{ period.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns is-flex is-align-items-center">
                <label class="column is-half label">Previous Closing Date</label>
                <div class="column is-half">
                  <v-date-picker
                    v-model="glCloseDate"
                    :masks="formats"
                    :attributes="attrs"
                    :locale="$userInfo.locale"
                    @input="glCloseDateInputEvent"
                    class="is-fullwidth"
                    :timezone="$filters.getIanaTimezone()">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="field has-addons mb-0">
                        <div class="control is-expanded has-icons-left">
                          <input type="text"
                            class="input"
                            :value="inputValue"
                            v-on="inputEvents">
                          <span class="icon is-small is-left">
                            <i class="mdi mdi-calendar mdi-18px" />
                          </span>
                        </div>
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field columns tile is-child">
          <div class="column is-half pl-0 py-0">
            <p class="label">
              <label class="title is-6">External Accounting</label>
            </p>
            <div class="columns">
              <div class="column is-half label">External account type</div>
              <div class="column is-half">
                <div class="control is-extended">
                  <div class="select is-fullwidth">
                    <select v-model="companySetting.externalAccounting">
                      <option value="null"
                        disabled>Select</option>
                      <option value="NONE">None</option>
                      <option value="MYOB">MYOB</option>
                      <option value="MONEYWORKS">MoneyWorks</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field tile is-child">
          <p class="label">
            <label class="title is-6">Other Settings</label>
          </p>
          <div class="columns">
            <div class="column is-half">
              <div class="columns">
                <div class="column is-half label mb-0">Print Ruler Line</div>
                <div class="column is-half">
                  <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                    <input v-model="companySetting.shadow"
                      type="checkbox">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-half label">Allow Invoice Previous Month</div>
                <div class="column is-half">
                  <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                    <input v-model="companySetting.allowInvoicePreviousMonth"
                      type="checkbox">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="columns">
                <div class="column is-half label mb-0">Show Driveable</div>
                <div class="column is-half">
                  <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                    <input v-model="driveable"
                      type="checkbox">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-half label">Default Driveable</div>
                <div class="column is-half">
                  <span class="mr-1">{{ companySetting.driveableDefault ? 'Yes' : 'No' }}</span>
                  <input id="driveableDefault"
                    class="switch is-rounded"
                    type="checkbox"
                    v-model="companySetting.driveableDefault">
                  <label for="driveableDefault" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field tile is-child">
          <p class="label">
            <label class="title is-6">Email Brief Letterhead</label>
          </p>
          <textarea class="textarea"
            rows="6"
            v-model="companySetting.address" />
        </div>

        <div class="field tile is-child">
          <p class="label">
            <label class="title is-6">Letter Head To Print</label>
          </p>
          <div class="columns is-flex is-align-items-center">
            <div class="column is-half">
              <div class="columns is-flex is-align-items-center">
                <div class="column is-half label">Head format</div>
                <div class="column is-half">
                  <div class="control is-extended">
                    <div class="select is-fullwidth">
                      <select v-model="companySetting.paperType">
                        <option value="null"
                          disabled>Select</option>
                        <option v-for="(letterHead, index) in letterHeaderToPrint"
                          :key="index"
                          :value="parseInt(letterHead.value)">{{ letterHead.label }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="columns">
                <div class="column is-half label">Print on every page</div>
                <div class="column is-half">
                  <div class="pretty p-icon p-curve p-smooth m-0 p-bigger is-two-third">
                    <input v-model="companySetting.printLhOnEveryPage"
                      type="checkbox">
                    <div class="state p-info">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="companySetting.paperType == '2'">
            <div v-if="companySetting.pageHeaderImage"
              class="is-flex is-align-items-center">
              <img :src="pageHeaderImage"
                class="pageHeaderImg mr-5"
                alt="Company Letter Header To Print">
              <button class="button has-text-danger"
                @click="deletePageHeaderImage"
                title="Delete">
                <i class="icon mdi mdi-delete mdi-24px" />
              </button>
            </div>
            <div v-else>
              <vue-dropzone
                ref="pageHeaderImgDropzone"
                :id="'pageHeaderImgDropzone'"
                :options="dropzoneOptions"
                @vdropzone-mounted="dropzoneMounted"
                @vdropzone-success="dropzoneSuccess"
                @vdropzone-error="dropzoneError" />
            </div>
          </div>

          <confirm-modal v-if="companySetting.pageHeaderImage"
            :active.sync="isConfirmModalActive"
            @ok="deleteImage(true)"
            @cancel="deleteImage(false)"
            :ok-text="'Yes'"
            :cancel-text="'No'">
            <p slot="text-title">Delete Company Paper Head Image</p>
            <p slot="text-content">
              Company Paper Head Image will be deleted. Continue?
            </p>
          </confirm-modal>

          <div v-if="companySetting.paperType == '1'">
            <div class="mb-3">
              <label class="label">Company name header</label>
              <quill-editor
                v-model="companySetting.companyNameHeader" />
            </div>
            <div>
              <label class="label">Company page header</label>
              <quill-editor
                v-model="companySetting.pageHeader" />
            </div>
          </div>
        </div>

        <div class="field tile is-child">
          <p class="label">
            <label class="title is-6">Footer</label>
          </p>
          <div class="field tabs is-boxed">
            <ul>
              <li v-for="(footer, index) in footers"
                :key="index"
                :class="{ 'is-active' : activeFooterTabIndex === index }">
                <a @click="activeFooterTabIndex = index">{{ footer.label }}</a>
              </li>
            </ul>
          </div>
          <div class="field">
            <textarea class="textarea"
              rows="6"
              v-model="activeFooter" />
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import CommonApiService from '@/services/common'
import { CompanySettingService } from '@/services'
import { ConfirmModal } from '@/components/BulmaModal'
import QuillEditor from '@/components/QuillEditor/QuillEdtor.vue'
import StoreMixin from './storeMixin'
import VueDropzone from '@/components/VueDropzone'

export default {
  name: 'CompanySetting',
  components: {
    ConfirmModal,
    QuillEditor,
    VueDropzone
  },
  mixins: [StoreMixin],
  data() {
    return {
      activeFooterTabIndex: 0,
      atglAccounts: null,
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      companySetting: null,
      defaultNumbers: null,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_WEBAPI_URI}/companysettings/uploadCompanyPageHeaderImage`,
        thumbnailWidth: 128,
        thumbnailHeight: 128,
        addRemoveLinks: true,
        maxFilesize: 5,
        maxFiles: 1,
        acceptedFiles: 'image/*',
        headers: {
          Authorization: ''
        },
        dictDefaultMessage: '<i class="icon mdi mdi-cloud-upload mdi-36px"></i><br>click or drop your image here',
        dictMaxFilesExceeded: 'You can only upload one file.',
        dictResponseError: 'Failed to upload the image.'
      },
      externalAccoutSetting: [
        {value: 'NONE', label: 'None'},
        {value: 'MYOB', label: 'MYOB'},
        {value: 'MONEYWORKS', label: 'MoneyWorks'}
      ],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['DD/MM/YYYY'],
        data: ['DD/MM/YYYY'],
        dayPopover: null
      },
      glCloseDate: null,
      letterHeaderToPrint: [
        {value: 0, label: 'Nothing'},
        {value: 1, label: 'Text Header'},
        {value: 2, label: 'Graphics Header'}
      ],
      isCollectedDropdownActive: false,
      isConfirmModalActive: false
    }
  },
  computed: {
    batchNo: {
      get() {
        return this.companySetting?.batchNo ?? null
      },
      set(newVal) {
        if (!this.companySetting) return
        this.companySetting.batchNo = (newVal == '' || newVal ==  null) ? 0 : newVal
      }
    },
    driveable: {
      get() {
        return this.companySetting?.driveable
      },
      set(newVal) {
        if (!newVal) {
          this.companySetting.driveableDefault = false
        }
        this.companySetting.driveable = newVal
      }
    },
    gstPeriod() {
      if (this.$company.info.countryCode == 'NZ') {
        return [{ period: 2, label: 2 },
                { period: 6, label: 6 }]
      }
      return [{ period: 4, label: 4 },
              { period: 12, label: 12 }]
    },
    pageHeaderImage() {
      if (!this.companySetting || !this.companySetting.pageHeaderImage) { return '' }
      return 'data:image/jpg;base64,' + this.companySetting.pageHeaderImage
    },
    footers() {
      if (!this.companySetting) { return [] }
      return [
        {value: this.companySetting.quoteFooter, label: 'Quote Footer', type: 'quoteFooter'},
        {value: this.companySetting.invoiceFooter, label: 'Invoice Footer', type: 'invoiceFooter'},
        {value: this.companySetting.statementFooter, label: 'Statement Footer', type: 'statementFooter'},
        {value: this.companySetting.jobCostingFooter, label: 'Job Card Footer', type: 'jobCostingFooter'},
        {value: this.companySetting.jobCoverSheetFooter, label: 'Job Cover Footer', type: 'jobCoverSheetFooter'},
        {value: this.companySetting.partFooter, label: 'Parts Footer', type: 'partFooter'},
        {value: this.companySetting.purchaseOrderFooter, label: 'Order Footer', type: 'purchaseOrderFooter'},
        {value: this.companySetting.creditReturnFooter, label: 'CR Footer', type: 'creditReturnFooter'}
      ]
    },
    activeFooter: {
      get() {
        if (!this.footers) return null
        return this.footers[this.activeFooterTabIndex].value
      },
      set(newVal) {
        if (!this.footers) return null
        this.companySetting[this.footers[this.activeFooterTabIndex].type] = newVal
      }
    }
  },
  watch: {
    defaultNumbers: {
      deep: true,
      handler(value) {
        if (!value) return
        for (var i = 0; i < value.length; i++) {
          for (var j = 0; j < value[i].defaultValue.length; j++) {
            this.companySetting[value[i].prefix + value[i].defaultValue[j].type] =
              value[i].defaultValue[j].value
          }
        }
      }
    },
    companySetting: {
      deep: true,
      handler(val) {
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
        if (!val) {
          this.defaultNumbers = []
          return
        }
        this.defaultNumbers = [
          {
            defaultValue: [
              { label: 'Prefix', value: val.quotePrefix, type: 'Prefix' },
              { label: 'Suffix', value: val.quoteSuffix, type: 'Suffix' },
              { label: 'Next No', value: val.quoteValue, type: 'Value' }
            ],
            title: 'Default Quote Number',
            prefix: 'quote'
          },
          {
            defaultValue: [
              { label: 'Prefix', value: val.invoicePrefix, type: 'Prefix'  },
              { label: 'Suffix', value: val.invoiceSuffix, type: 'Suffix'  },
              { label: 'Next No', value: val.invoiceValue, type: 'Value'  }
            ],
            title: 'Default Invoice Number',
            prefix: 'invoice'
          },
          {
            defaultValue: [
              { label: 'Prefix', value: val.orderPrefix, type: 'Prefix'  },
              { label: 'Suffix', value: val.orderSuffix, type: 'Suffix'  },
              { label: 'Next No', value: val.orderValue, type: 'Value'  }
            ],
            title: 'Default Order Number',
            prefix: 'order'
          }
        ]
      }
    }
  },
  async created() {
    this.$showSpinner()
    await this.getCompanyATGLAccounts()
    this.initializeAutoUpdate()
    this.companySetting = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getEntity' }))
    this.dropzoneOptions.headers.Authorization = `Bearer ${CommonApiService.getAuthToken()}`
    this.getGlCloseDate()
    this.$hideSpinner()
  },
  methods: {
    async deleteImage(deleteConfirmed) {
      this.isConfirmModalActive = false
      if (!deleteConfirmed) return
      this.$showSpinner()
      let tmpCompanySetting = _cloneDeep(this.companySetting)
      tmpCompanySetting.pageHeaderImage = null
      this.saveSnapshot({ entity: _cloneDeep(tmpCompanySetting) })
      await this.updateCompanySetting(tmpCompanySetting)
      this.$hideSpinner()
    },
    deletePageHeaderImage() {
      this.isConfirmModalActive = true
    },
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    dropzoneMounted() {
      this.$refs.pageHeaderImgDropzone.getAcceptedFiles()
    },
    async dropzoneSuccess(file, response) {
      this.$notification.success('Company Page Header Image', response)
      this.companySetting = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getEntity' }))
    },
    dropzoneError(file, message, xhr){
      if (message) {
        this.$notification.error('Company Page Header Image', message)
      }
    },
    async getCompanyATGLAccounts() {
      this.atglAccounts = await CompanySettingService.getCompanyATGLAccounts()
    },
    glCloseDateInputEvent(date) {
      const dateTime = new Date(date)
      dateTime.setHours(0, 0, 0, 0)
      this.companySetting.glCloseDate = dateTime.toISOString().split('.')[0] + 'Z'
    },
    getGlCloseDate() {
      if (this.companySetting && this.companySetting.glCloseDate) {
        this.glCloseDate = new Date(this.companySetting.glCloseDate + 'Z')
      }
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateCompanySetting(value)
      }, this.debounceDelay)
    },
    async updateCompanySetting(companySetting) {
      if (!this.snapshotDiff) return
      companySetting.deepDiff = JSON.stringify(this.snapshotDiff)
      this.companySetting = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanySetting', entity: companySetting }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateCompanySetting(this.companySetting)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

<style lang="scss" scoped>
p.label {
  margin-bottom: 1em;
}

.tabs {
  a {
    padding: 0.1rem 0.5rem;
  }

  ul {
    width: 10em;
  }
}

.field.tile.is-child {
  padding: 1.25rem;
  border-bottom: 2px solid #F5F5F5;

  &:last-child {
    border: none;
  }
}

.pageHeaderImg {
  min-height: 200px;
  max-height: 300px;
  min-width: 650px;
  max-width: 720px;
}

#pageHeaderImgDropzone {
  background:#f8f9fa;
  border: 2px dashed #3291EF;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #71b3ff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3291EF;
}
</style>

<style lang="scss">
.dropzone .dz-preview .dz-error-message {
  top: 150px!important;
}
</style>